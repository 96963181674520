<template>
  <base-material-card
    id="tattooer-list"
    :title="$t('list', { name: $tc('tattooer', 2) })"
    icon="mdi-account-edit"
  >
    <v-row align="center" class="pa-md-3">
      <v-col
        :order="$vuetify.breakpoint.smAndDown ? 1 : ''"
        cols="8"
        sm="9"
        md="2"
        class="md-shorter py-0"
      >
        <v-text-field
          outlined
          dense
          v-model="filters.search"
          prepend-inner-icon="mdi-magnify"
          class="ml-auto filters search"
          :label="$t('search')"
          hide-details
          single-line
          clearable
          @input="searchFilter"
        />
      </v-col>
      <v-col
        v-if="$store.getters['auth/isStudio'] && $vuetify.breakpoint.smAndDown"
        cols="4"
        sm="3"
        :order="
          $vuetify.breakpoint.xsOnly ? 2 : $vuetify.breakpoint.smOnly ? 2 : ''
        "
        md="2"
        class="md-shorter py-0"
      >
        <v-row>
          <v-col cols="12" md="3" align="right" class="pt-0 pt-md-3 py-0">
            <v-btn
              elevation="0"
              icon
              @click="
                $router.push({
                  name: 'UserSettings',
                  params: { type: 'alerts' },
                })
              "
            >
              <template>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon size="25" v-on="on"> mdi-swap-vertical </v-icon>
                  </template>
                  {{ $t("exp-imp") }}
                </v-tooltip>
              </template>
            </v-btn>
            <v-menu>
              <template v-slot:activator="{ on }">
                <v-btn icon v-on="on">
                  <v-icon size="36">mdi-plus-circle</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item class="lessMargin" @click="invite('invite')">
                  <v-list-item-icon>
                    <v-icon
                      style="
                        margin-top: -4px !important;
                        margin-left: -1px !important;
                      "
                    >
                      mdi-email-outline
                    </v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title>
                      {{ $t("invite", { name: "" }) }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item class="lessMargin" @click="invite('guest')">
                  <v-list-item-icon>
                    <v-icon
                      style="
                        margin-top: -4px !important;
                        margin-left: -1px !important;
                      "
                    >
                      mdi-plus
                    </v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title>
                      {{ $t("add", { name: "" }) }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-col>
        </v-row>
      </v-col>
      <v-col
        :order="
          $vuetify.breakpoint.xsOnly ? 3 : $vuetify.breakpoint.smOnly ? 3 : ''
        "
        cols="6"
        sm="6"
        md="2"
        class="md-shorter pb-0 pb-md-0 py-0"
      >
        <v-select
          v-model="filters.tattooer_status"
          :label="$t('tattooers.state')"
          :items="itemswip"
          clearable
          single-line
          hide-details
          class="filters"
          @input="searchFilter"
          outlined
          dense
        ></v-select>
      </v-col>
      <v-col
        :order="
          $vuetify.breakpoint.xsOnly ? 2 : $vuetify.breakpoint.smOnly ? 4 : ''
        "
        cols="6"
        sm="6"
        md="2"
        class="md-shorter pb-0 pb-md-0 py-0"
      >
        <v-select
          v-model="filters.type_artist"
          :label="$t('tattooers.role')"
          :items="itemRole"
          item-value="id"
          :item-text="(x) => $tc(x.name)"
          clearable
          hide-details
          single-line
          class="filters"
          @input="searchFilter"
          outlined
          dense
        ></v-select>
      </v-col>

      <!--fin-->
      <v-spacer v-if="$vuetify.breakpoint.mdAndUp"></v-spacer>
      <v-col
        cols="6"
        md="2"
        align="right"
        v-if="$store.getters['auth/isAdmin']"
      >
        <v-btn
          dark
          @click="$router.push({ name: 'tattooerCreate' })"
          style="height: 30px; margin-top: 5px"
          elevation="0"
        >
          <v-icon>mdi-plus</v-icon>
          {{ $t("new", { name: $tc("artist") }) }}
        </v-btn>
      </v-col>

      <v-col
        cols="12"
        md="3"
        align="right"
        class="py-0"
        :order="$vuetify.breakpoint.smAndDown ? 4 : ''"
        v-if="$store.getters['auth/isStudio'] && !$vuetify.breakpoint.smAndDown"
      >
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              class="mr-3 my-0"
              fab
              x-small
              v-on="on"
              elevation="0"
              :outlined="displayCards !== 'cards'"
              @click="
                displayCards = 'cards';
                fetchTattooers();
              "
            >
              <v-icon size="15">$square</v-icon>
            </v-btn>
          </template>
          <span>Cards</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              value="list"
              class="my-0"
              fab
              x-small
              elevation="0"
              :outlined="displayCards !== 'list'"
              @click="
                displayCards = 'list';
                fetchTattooers();
              "
              v-on="on"
            >
              <v-icon size="15">$list</v-icon>
            </v-btn>
          </template>
          <span>Tabla</span>
        </v-tooltip>

        <v-btn
          elevation="0"
          icon
          @click="
            $router.push({
              name: 'UserSettings',
              params: { type: 'alerts' },
            })
          "
        >
          <template>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon size="25" v-on="on"> mdi-swap-vertical </v-icon>
              </template>
              {{ $t("exp-imp") }}
            </v-tooltip>
          </template>
        </v-btn>
        <v-menu>
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on">
              <v-icon size="36">mdi-plus-circle</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item class="lessMargin" @click="invite('invite')">
              <v-list-item-icon>
                <v-icon
                  style="
                    margin-top: -4px !important;
                    margin-left: -1px !important;
                  "
                >
                  mdi-email-outline
                </v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>
                  {{ $t("invite", { name: "" }) }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item class="lessMargin" @click="invite('guest')">
              <v-list-item-icon>
                <v-icon
                  style="
                    margin-top: -4px !important;
                    margin-left: -1px !important;
                  "
                >
                  mdi-plus
                </v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>
                  {{ $t("add", { name: "" }) }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>
    </v-row>
    <!-- inicio cards-->

    <div v-if="this.displayCards == 'cards'">
      <v-row :class="$vuetify.breakpoint.mdAndDown ? 'center' : ''">
        <v-col
          v-for="(item, i) in items"
          :key="`dir-${i}`"
          cols="6"
          md="3"
          class="px-1 px-md-2"
        >
          <base-contact-card
            :url="item.user.profile_photo ? item.user.profile_photo.url : null"
            :title="item.user.fullname"
            :phone="item.user.phone"
            :email="item.user.email"
          >
            <template slot="actions">
              <Configuration
                :ref="`configuration-card-${item.id}`"
                :tattooer="item"
                @update="fetchTattooers"
              />
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-on="on" v-bind="attrs" large
                    >mdi-dots-horizontal</v-icon
                  >
                </template>
                <v-list>
                  <v-list-item
                    class="lessMargin"
                    @click="
                      $router.push({
                        name: 'tattooerView',
                        params: { id: item.id },
                      })
                    "
                  >
                    <v-list-item-icon>
                      <v-icon small style="margin-top: -4px !important">
                        $eye
                      </v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ $t("view", { name: $tc("artist") }) }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item
                    v-if="
                      $store.getters['auth/isStudio'] &&
                      statusTattooer(item).archivar
                    "
                    @click="$refs[`configuration-card-${item.id}`].open()"
                  >
                    <v-list-item-icon class="mr-1 my-2">
                      <v-icon
                        style="margin-left: -1px; margin-top: -1.5px !important"
                        size="17"
                      >
                        mdi-cog-outline
                      </v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ $t("configurate", { name: $tc("artist") }) }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>

                  <template
                    v-if="
                      false &&
                      $store.getters['auth/isStudio'] &&
                      item.tattooer_studios.length &&
                      item.tattooer_studios[0].status === 'confirmed'
                    "
                  >
                    <v-list-item
                      v-for="(type, i) in item.type_artist"
                      :key="i"
                      class="lessMargin"
                      @click="formStudio(item, type)"
                    >
                      <v-list-item-icon>
                        <v-icon
                          small
                          style="
                            margin-top: -4px !important;
                            margin-left: -1px !important;
                          "
                        >
                          $request
                        </v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ $t("tattooers.inscription") }}
                          {{ $tc(type.name) }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                  <v-list-item
                    v-if="$store.getters['auth/isAdmin']"
                    class="lessMargin"
                    @click="
                      $router.push({
                        name: 'customer_inscription_tattooer',
                        params: { token: item.token },
                      })
                    "
                  >
                    <v-list-item-icon>
                      <v-icon small style="margin-top: -4px !important">
                        $request
                      </v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ $t("tattooers.inscription") }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item
                    v-if="$store.getters['auth/isAdmin']"
                    class="lessMargin"
                    @click="
                      $router.push({
                        name: 'tattooerEdit',
                        params: { id: item.id },
                      })
                    "
                  >
                    <v-list-item-icon>
                      <v-icon small> $editprofile </v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ $t("edit-profile") }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item
                    v-if="!statusTattooer(item).archivar"
                    class="lessMargin"
                    @click="
                      $router.push({
                        name: 'tattooerInvite',
                        params: { email: item.user.email },
                      })
                    "
                  >
                    <v-list-item-icon>
                      <v-icon style="margin-top: -4px !important">
                        mdi-check-circle-outline
                      </v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ $t("tattooists_activate") }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>

                  <Archivar
                    :tattooer="item"
                    v-if="
                      statusTattooer(item).archivar &&
                      $store.getters['auth/isStudio']
                    "
                    @update="fetchTattooers"
                  />
                </v-list>
              </v-menu>
            </template>
            <v-row justify="space-around" class="mb-2">
              <EditTattooerTimetable
                :tattooer_id="item.id"
                :studio_id="$store.state.auth.user.studio.id"
                :ref="`timetable-${item.id}`"
                :icons="true"
              />
              <base-dialog
                type="icon"
                icon="$appointments"
                title="Agenda Artista"
                width="550px"
                small
              >
                <AgendaView
                  smaller
                  :selectedCalendars="
                    item.tattooer_studios.length
                      ? [item.tattooer_studios[0].studio_calendar_id]
                      : []
                  "
                />
              </base-dialog>
              <WhatsApp :phone_number="item.user.phone" />
            </v-row>
          </base-contact-card>
        </v-col>
      </v-row>
      <template>
        <div class="text-center">
          <v-pagination
            v-model="options_card.page"
            :length="pages"
            @input="fetchTattooers"
          ></v-pagination>
        </div>
      </template>
    </div>

    <base-data-table-cards
      v-else
      :headers="headers"
      :data="items"
      :server-items-length="totalTattooers"
      @update="fetchTattooers"
      ref="table"
      :headers_small="headers_small"
      :title="$t('artist')"
      :totalItems="totalTattooers"
    >
      <template slot="no_info">
        <v-row justify="center">
          <v-col class="pb-0" cols="12" align="center">
            <h2 class="primary--text">{{ $tc("artist", 2) }}</h2>
          </v-col>
          <v-col class="pt-0" cols="12" align="center">
            <span>{{ $t("create_new_m", { name: $tc("artist", 2) }) }}</span>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col align="center">
            <v-btn @click="invite('guest')">Crear Artista</v-btn>
          </v-col>
        </v-row>
      </template>

      <template v-slot:item_first_name="item">
        {{
          ((item.tattooer_studios.length &&
            item.tattooer_studios[0].status === "confirmed") ||
            item.tattooer_studios.length === 0) &&
          item.user.first_name
            ? item.user.first_name
            : "-"
        }}
      </template>

      <template v-slot:item_last_name="item"
        >{{
          ((item.tattooer_studios.length &&
            item.tattooer_studios[0].status === "confirmed") ||
            item.tattooer_studios.length === 0) &&
          item.user.last_name
            ? item.user.last_name
            : "-"
        }}
      </template>

      <template v-slot:item_phone="item"
        >{{
          ((item.tattooer_studios.length &&
            item.tattooer_studios[0].status === "confirmed") ||
            item.tattooer_studios.length === 0) &&
          item.user.phone
            ? item.user.phone
            : "-"
        }}
        <WhatsApp
          :phone_number="item.user.phone"
          v-if="
            (item.tattooer_studios.length &&
              item.tattooer_studios[0].status === 'confirmed') ||
            item.tattooer_studios.length === 0
          "
        />
      </template>
      <template v-slot:item_email="item">
        <v-tooltip bottom v-if="item.user.email">
          <template v-slot:activator="{ on }">
            <a
              @click="
                $router.push({
                  name: 'email',
                  params: { email: item.user.email },
                })
              "
              v-text="item.user.email"
              v-on="on"
            />
          </template>
          {{ item.user.email }}
        </v-tooltip>
        <span v-else>-</span>
      </template>
      <template v-slot:item_actions="item" v-if="$vuetify.breakpoint.mdAndUp">
        <Configuration
          :ref="`configuration-${item.id}`"
          :tattooer="item"
          @update="fetchTattooers"
        />
        <base-dialog
          type="hidden"
          icon="$appointments"
          title="Agenda Artista"
          width="550px"
          small
          :ref="`agenda-${item.id}`"
        >
          <AgendaView
            smaller
            :selectedCalendars="
              item.tattooer_studios.length
                ? [item.tattooer_studios[0].studio_calendar_id]
                : []
            "
          />
        </base-dialog>

        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-on="on" v-bind="attrs" large>mdi-dots-horizontal</v-icon>
          </template>
          <v-list>
            <v-list-item
              class="lessMargin"
              @click="
                $router.push({
                  name: 'tattooerView',
                  params: { id: item.id },
                })
              "
            >
              <v-list-item-icon>
                <v-icon small style="margin-top: -4px !important">
                  $eye
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  {{ $t("view", { name: $tc("artist") }) }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              v-if="
                $store.getters['auth/isStudio'] && statusTattooer(item).archivar
              "
              @click="$refs[`configuration-${item.id}`].open()"
            >
              <v-list-item-icon class="mr-1 my-2">
                <v-icon
                  style="margin-left: -1px; margin-top: -1.5px !important"
                  size="17"
                >
                  mdi-cog-outline
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  {{ $t("configurate", { name: $tc("artist") }) }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click="$refs[`agenda-${item.id}`].open()">
              <v-list-item-icon class="mr-1 my-2">
                <v-icon
                  style="margin-left: -1px; margin-top: -1.5px !important"
                  size="17"
                >
                  $appointments
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  {{ $t("agenda", { name: $tc("artist") }) }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <template
              v-if="
                false &&
                $store.getters['auth/isStudio'] &&
                item.tattooer_studios.length &&
                item.tattooer_studios[0].status === 'confirmed'
              "
            >
              <v-list-item
                v-for="(type, i) in item.type_artist"
                :key="i"
                class="lessMargin"
                @click="formStudio(item, type)"
              >
                <v-list-item-icon>
                  <v-icon
                    small
                    style="
                      margin-top: -4px !important;
                      margin-left: -1px !important;
                    "
                  >
                    $request
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ $t("tattooers.inscription") }} {{ $tc(type.name) }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
            <v-list-item
              v-if="$store.getters['auth/isAdmin']"
              class="lessMargin"
              @click="
                $router.push({
                  name: 'customer_inscription_tattooer',
                  params: { token: item.token },
                })
              "
            >
              <v-list-item-icon>
                <v-icon small style="margin-top: -4px !important">
                  $request
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  {{ $t("tattooers.inscription") }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              v-if="$store.getters['auth/isAdmin']"
              class="lessMargin"
              @click="
                $router.push({
                  name: 'tattooerEdit',
                  params: { id: item.id },
                })
              "
            >
              <v-list-item-icon>
                <v-icon small> $editprofile </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  {{ $t("edit-profile") }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              v-if="!statusTattooer(item).archivar"
              class="lessMargin"
              @click="
                $router.push({
                  name: 'tattooerInvite',
                  params: { email: item.user.email },
                })
              "
            >
              <v-list-item-icon>
                <v-icon style="margin-top: -4px !important">
                  mdi-check-circle-outline
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  {{ $t("tattooists_activate") }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <!-- <v-list-item
                class="lessMargin"
                @click="resetPassword(item)"
                v-if="
                  statusTattooer(item).archivar &&
                  $store.getters['auth/isStudio']
                "
              >
                <v-list-item-icon>
                  <v-icon small> mdi-lock-reset </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ $t("reset_password") }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>-->
            <Archivar
              :tattooer="item"
              v-if="
                statusTattooer(item).archivar && $store.getters['auth/isStudio']
              "
              @update="fetchTattooers"
            />

            <!--
                <v-list-item class="lessMargin">
                <v-list-item-icon>
                  <v-icon small>$color</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>
                    <ColorPicker
                      :tattooer="item"
                      v-if="
                        $store.getters['auth/isStudio']
                      " /></v-list-item-title
                ></v-list-item-content>
              </v-list-item>
              -->
          </v-list>
        </v-menu>
      </template>

      <template v-slot:item_actions="item" v-else>
        <v-row justify="center">
          <v-col
            cols="3"
            v-if="
              $store.getters['auth/isStudio'] && statusTattooer(item).archivar
            "
          >
            <Configuration
              :ref="`configuration-${item.id}`"
              :tattooer="item"
              @update="fetchTattooers"
            />
            <v-btn
              color="primary"
              fab
              outlined
              dark
              small
              v-on="on"
              @click="$refs[`configuration-${item.id}`].open()"
            >
              <v-icon> mdi-cog-outline </v-icon>
            </v-btn>
          </v-col>
          <v-col cols="3">
            <v-btn
              color="primary"
              fab
              dark
              outlined
              small
              v-on="on"
              @click="
                $router.push({
                  name: 'tattooerView',
                  params: { id: item.id },
                })
              "
            >
              <v-icon>$eye</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="3">
            <base-dialog
              type="fab"
              icon="$appointments"
              title="Agenda Artista"
              width="550px"
              small
            >
              <AgendaView
                smaller
                :selectedCalendars="
                  item.tattooer_studios.length
                    ? [item.tattooer_studios[0].studio_calendar_id]
                    : []
                "
              />
            </base-dialog>
          </v-col>
          <v-col
            cols="3"
            v-if="
              statusTattooer(item).archivar && $store.getters['auth/isStudio']
            "
          >
            <Archivar
              :title_show="$vuetify.breakpoint.mdAndUp ? true : false"
              :tattooer="item"
              @update="fetchTattooers"
            />
          </v-col>

          <template
            v-if="
              false &&
              $store.getters['auth/isStudio'] &&
              item.tattooer_studios.length &&
              item.tattooer_studios[0].status === 'confirmed'
            "
          >
            <v-list-item
              v-for="(type, i) in item.type_artist"
              :key="i"
              class="lessMargin"
              @click="formStudio(item, type)"
            >
              <v-list-item-icon>
                <v-icon
                  small
                  style="
                    margin-top: -4px !important;
                    margin-left: -1px !important;
                  "
                >
                  $request
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  {{ $t("tattooers.inscription") }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
          <v-list-item
            v-if="$store.getters['auth/isAdmin']"
            class="lessMargin"
            @click="
              $router.push({
                name: 'tattooerEdit',
                params: { id: item.id },
              })
            "
          >
            <v-list-item-icon>
              <v-icon small> $editprofile </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                {{ $t("edit-profile") }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-col cols="3" v-if="!statusTattooer(item).archivar">
            <v-btn
              color="primary"
              fab
              dark
              outlined
              small
              v-on="on"
              @click="
                $router.push({
                  name: 'tattooerInvite',
                  params: { email: item.user.email },
                })
              "
            >
              <v-icon> mdi-check-circle-outline</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </template>

      <template v-slot:item_state="item">
        <v-menu>
          <template v-slot:activator="{ on }">
            <v-chip
              class="status_chip"
              :color="statusTattooer(item).color"
              style="color: black"
              v-on="on"
            >
              {{
                $t(statusTattooer(item).text, {
                  date: $d(statusTattooer(item).date, "dayMonth"),
                })
              }}

              <v-icon color="black" right small>mdi-information</v-icon>
            </v-chip>
          </template>
          <v-list>
            <v-list-item>
              <v-list-item-icon class="my-1 mr-1">
                <v-icon class="mt-1" color="primary" small> $tattooers </v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title v-if="item.tattoerType == 'tattoer'">
                  {{ $t("artists.added") }}</v-list-item-title
                >
                <v-list-item-title v-else-if="item.tattoerType == 'guest'">
                  {{ $t("artists.guest") }}</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-icon class="my-1 mr-1">
                <v-icon
                  class="mt-1"
                  color="success"
                  v-if="item.token_payment.length > 0"
                >
                  mdi-check
                </v-icon>
                <v-icon class="mt-1" color="red" v-else> mdi-close </v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title> Paga Y Señal</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-icon class="my-1 mr-1">
                <v-icon
                  class="mt-1"
                  color="success"
                  v-if="item.comisionsTattooer"
                >
                  mdi-check
                </v-icon>
                <v-icon class="mt-1" color="red" v-else> mdi-close </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title v-if="item.comisionsTattooer">
                  Comisiones Activadas</v-list-item-title
                >
                <v-list-item-title v-else>
                  Comisiones Desactivadas</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
      <template v-slot:item_gender="item">
        <v-icon v-if="item.gender == 'm'">mdi-gender-male</v-icon>
        <v-icon v-else>mdi-gender-female</v-icon>
      </template>
      <!--
        <template v-slot:item_token_payment="item">
          <v-tooltip
            v-if="item.token_payment.length"
            class="success--text"
            bottom
          >
            <template v-slot:activator="{ on }">
              <v-icon v-on="on" color="success">mdi-check</v-icon>
            </template>
            {{ $t("active") }}
          </v-tooltip>
          <v-tooltip v-else class="login_error--text" bottom>
            <template v-slot:activator="{ on }">
              <v-icon v-on="on" color="login_error">mdi-close</v-icon>
            </template>
            {{ $t("deactive") }}</v-tooltip
          >
        </template>
        -->

      <template v-slot:item_type_artist="item">
        <template
          v-if="
            item.tattooer_studios.length &&
            item.tattooer_studios[0].status === 'confirmed'
          "
        >
          <span v-for="(s, i) in services(item)" :key="i">
            <span v-if="i > 0">/</span>
            {{ s[0] }}
          </span>
          <span v-if="services(item).length === 0" class="login_error--text">
            {{ $t("no_service") }}
          </span>
        </template>
        <span v-else>-</span>
      </template>

      <template v-slot:item_fullname_email="item">
        <div class="no-wrap">
          <b>{{ item.user.fullname }}</b>
        </div>
        <div class="no-wrap">{{ item.user.email }}</div>
      </template>

      <template v-slot:item_avatar="item">
        <v-avatar
          class="ml-4"
          color="primary"
          :size="40"
          style="
            margin: 0 auto;
            font-size: 25px;
            text-transform: uppercase;
            color: #272727;
            cursor: pointer;
          "
        >
          <span>{{ nameAvatar(item.user.fullname) }}</span>
        </v-avatar>
      </template>
    </base-data-table-cards>
  </base-material-card>
</template>
<script>
import { mapActions } from "vuex";

import { debounce } from "lodash";
export default {
  name: "TattooerList",

  components: {
    ColorPicker: () => import("@/components/ui/ColorPicker"),
    Archivar: () => import("@/components/tattooer/Archivar"),
    Import: () => import("@/components/exp-imp/Import"),
    TattooType: () => import("@/components/user/settings/profile/TattooTypes"),
    Configuration: () => import("@/components/tattooer/Configuration"),
    AgendaView: () => import("@/components/calendar/AgendaView"),
    EditTattooerTimetable: () =>
      import("@/components/user/timetable/Configuration"),
  },
  data() {
    return {
      displayCards: "list",
      items: null,
      itemswip: [
        { text: this.$t("all"), value: "all" },
        { text: this.$t("tattooers.states.actives"), value: "actives" },
        { text: this.$t("tattooers.states.unactive"), value: "unactive" },
      ],
      itemRole: [
        this.$t("all"),
        this.$t("tattooers.roles.tattooer"),
        this.$t("tattooers.roles.piercer"),
      ],
      filters: {
        search: "",
      },
      timetableCheck: false,
      totalTattooers: 0,
      sortBy: null,
      rowsPerPageItems: [10, 25, 50, 100, 200],
      options: {},
      options_card: {
        itemsPerPage: 8,
        page: 1,
      },
      loading: true,
      menu_invite: false,
      headers_small: [
        { value: "avatar", no_col: true },
        { value: "fullname_email" },
        { value: "state", no_col: true },
      ],
    };
  },
  computed: {
    pages() {
      return Math.ceil(this.totalTattooers / this.options_card.itemsPerPage);
    },
    headers() {
      if (this.$store.getters["auth/isStudio"]) {
        let headeres = [
          {
            text: this.$t("first_name"),
            sortable: false,
            value: "first_name",
            align: "center",
          },
          {
            text: this.$t("last_name"),
            sortable: false,
            value: "last_name",
            align: "center",
          },
          {
            text: this.$t("artists"),
            sortable: false,
            value: "type_artist",
            align: "center",
          },
          {
            text: this.$t("email"),
            sortable: false,
            value: "email",
            align: "center",
          },
          {
            text: this.$t("phone"),
            sortable: false,
            value: "phone",
            align: "center",
          },
        ];
        if (this.$store.getters["auth/getSetting"]("payment_appointment") == 1)
          // headeres.push({
          //   text: this.$t("tattooers.token_payment"),
          //   sortable: false,
          //   value: "token_payment",
          //   align: "center",
          // });
          headeres.push({
            style:
              this.$store.getters["auth/getSetting"]("payment_appointment") == 1
                ? ""
                : "min-width: 300px !important",
            text: this.$t("tattooers.state"),
            sortable: false,
            value: "state",
            align: "center",
          });
        headeres.push({
          text: this.$t("actions"),
          sortable: false,
          value: "actions",
          align: "center",
        });
        return headeres;
      }

      return [
        {
          text: this.$t("first_name"),
          sortable: false,
          value: "first_name",
        },
        {
          text: this.$t("last_name"),
          sortable: false,
          value: "last_name",
        },
        {
          text: this.$t("artist"),
          sortable: false,
          value: "type_artist",
        },
        {
          text: this.$t("email"),
          sortable: false,
          value: "email",
        },
        {
          text: this.$t("phone"),
          sortable: false,
          value: "user.phone",
        },

        // {
        //   text: this.$t("tattooers.token_payment"),
        //   sortable: false,
        //   value: "token_payment",
        // },
        {
          text: this.$t("actions"),
          sortable: false,
          value: "actions",
        },
      ];
    },

    myServices() {
      let s = this.$store.getters["auth/getSetting"]("studio_services");
      if (s) {
        return JSON.parse(s);
      }
      return [];
    },
  },

  mounted() {
    //console.log( "OS: " + detector.os());
    if (this.$store.getters["auth/isStudio"]) {
      this.filters.studio_id = this.$store.state.auth.user.studio.id;
    }
    this.fetchTattooers();
    this.fetchTypes();
    this.fetchStudio();
  },
  methods: {
    nameAvatar(item) {
      //console.log(arr);
      let arr = item.split(" ");
      //console.log(arr);
      let nom = "";

      arr.forEach((word, i) => {
        if (nom.length < 3 && word.length > 0) nom += word[0];
      });

      return nom;
    },
    services(artist) {
      let text = [];

      artist.type_artist.forEach((ta) => {
        let s = this.myServices.find((x) => x.name === ta.name);
        if (s) {
          text.push(this.$tc(ta.name));
        }
      });
      return text;
    },
    ...mapActions("studios", ["getStudio"]),

    fetchStudio() {
      this.loading = true;
      this.getStudio(this.$store.state.auth.user.id).then((studio) => {
        this.timetableCheck = false;
        studio.timetable.forEach((element) => {
          if (element.length > 0) this.timetableCheck = true;
        });
      });
    },
    formStudio(tattooer, type) {
      if (
        this.timetableCheck &&
        this.$store.getters["auth/getLaborExange"] !== null
      )
        this.$router.push({
          name: "customer_inscription_studio",
          params: {
            token: this.$store.state.auth.user.studio.token,
            tattooer_id: tattooer.id,
            artist: type.name,
          },
        });
      else
        this.$confirm(this.$t("appointments.info_timetable"), "", "").then(
          () => {
            this.$router.push({
              name: "UserSettings",
              params: { type: "alerts", calendar: true },
            });
          }
        );
    },

    invite(where) {
      if (
        this.timetableCheck &&
        this.$store.getters["auth/getLaborExange"] !== null &&
        this.$store.getters["auth/getSetting"]("policy_appointment") !== null &&
        ((this.$store.getters["auth/getSetting"]("policy_appointment") ===
          "link" &&
          this.$store.getters["auth/getSetting"]("link_appointment") != null) ||
          (this.$store.getters["auth/getSetting"]("policy_appointment") ===
            "page" &&
            this.$store.getters["auth/getSetting"]("page_policy_appointment") !=
              null))
      ) {
        if (where === "invite") this.$router.push({ name: "tattooerInvite" });
        if (where === "guest") this.$router.push({ name: "tattooerGuest" });
      } else {
        if (
          !this.timetableCheck &&
          this.$store.getters["auth/getLaborExange"] === null
        ) {
          this.$confirm(this.$t("appointments.info_both"), "", "").then(() => {
            this.$router.push({
              name: "UserSettings",
              params: { type: "alerts", calendar: true },
            });
          });
        } else if (this.$store.getters["auth/getLaborExange"] === null) {
          this.$confirm(this.$t("appointments.info_system"), "", "").then(
            () => {
              this.$router.push({
                name: "UserSettings",
                params: { type: "alerts", system: true },
              });
            }
          );
        } else if (
          this.$store.getters["auth/getSetting"]("policy_appointment") ===
            null ||
          (this.$store.getters["auth/getSetting"]("policy_appointment") ===
            "link" &&
            this.$store.getters["auth/getSetting"]("link_appointment") ==
              null) ||
          (this.$store.getters["auth/getSetting"]("policy_appointment") ===
            "page" &&
            this.$store.getters["auth/getSetting"]("page_policy_appointment") ==
              null)
        ) {
          this.$confirm(this.$t("appointments.info_policy"), "", "").then(
            () => {
              this.$router.push({
                name: "UserSettings",
                params: { type: "alerts", system: true },
              });
            }
          );
        } else {
          this.$confirm(this.$t("appointments.info_timetable"), "", "").then(
            () => {
              this.$router.push({
                name: "UserSettings",
                params: { type: "alerts", calendar: true },
              });
            }
          );
        }
      }
    },
    ...mapActions("users", ["restablishPassword"]),
    ...mapActions("tattooers", ["getTattooers", "getTypeArtist"]),
    resetPassword(t) {
      this.$confirm(this.$t("reset_password_question"), "", "").then(() => {
        this.restablishPassword(t.id).then(() => {
          this.fetchTattooers();
        });
      });
    },
    fetchTypes() {
      this.loading = true;

      //console.log("%c%s", "color: #0088cc", "test");
      this.getTypeArtist().then((tattooers) => {
        //console.log(tattooers);
        this.itemRole = [{ id: null, name: "all" }, ...tattooers.data];
        this.loading = false;
      });
    },
    statusTattooer(tattooer) {
      let activeTattooerStudios = tattooer.tattooer_studios[0] || null;

      if (
        activeTattooerStudios &&
        activeTattooerStudios.status !== "archived"
      ) {
        let now = new Date();
        let from = new Date(activeTattooerStudios.from);
        let to = activeTattooerStudios.to
          ? new Date(activeTattooerStudios.to)
          : null;
        if (activeTattooerStudios.status !== "confirmed")
          return {
            text: "tattooers.states.invited",
            color: "green1",
            date: from,
            archivar: true,
          };
        if (now.getTime() >= from.getTime()) {
          let holiday = tattooer.vacation_tattooer.find((vc) => {
            let start = new Date(vc.start_date);
            let end = new Date(vc.end_date);
            return (
              now.getTime() >= start.getTime() && now.getTime() <= end.getTime()
            );
          });
          if (holiday)
            return {
              text: "tattooers.states.vacations",
              color: "primary",
              date: to,
              archivar: true,
            };
          if (to) {
            return {
              text: "tattooers.states.active_until",
              color: "primary",
              date: to,
              archivar: true,
            };
          }
          return {
            text: "tattooers.states.active",
            color: "primary",
            archivar: true,
          };
        } else
          return {
            text: "tattooers.states.will_active",
            color: "login_error",
            date: from,
            archivar: true,
          };
      } else {
        return {
          text: "tattooers.states.unactive",
          color: "gris1",
          archivar: false,
        };
      }

      return activeTattooerStudios;
    },

    fetchTattooers() {
      this.loading = true;
      this.getTattooers({
        pagination:
          this.displayCards == "cards"
            ? this.options_card
            : this.$refs.table.options,
        filters: this.filters,
      }).then((tattooers) => {
        this.items = tattooers.data;
        console.log(this.items);
        this.totalTattooers = tattooers.total;
        this.loading = false;
      });
    },
    searchFilter: debounce(function (val) {
      this.fetchTattooers();
    }, 1000),
  },
};
</script>
<style lang="sass">
@media (max-width: 767px)
  .v-card
    .v-chip.status_chip
      margin-right: 16px
  .v-dialog
    .v-card
      .v-chip.status_chip
        margin-right: 0px

.v-card__actions
  .v-btn
    padding: 0px !important
#tattooer-list
  .v-chip__content
    text-transform: uppercase
    font-size: 12px
    color: black

  @media (min-width: 800px)
    .shorter
      max-width: 187px
      height: 61px
.filters .svg-icon, .filters .v-icon
  fill: var(--v-primary-base)
  color: var(--v-primary-base)
  height: 20px

.filters .v-label
  color: white
.v-data-table__mobile-row__cell
  max-width: 210px !important
.lessMargin
  height: 30px
  cursor: pointer
  .v-list-item__icon
    margin-right: 5px !important

  .v-icon, .svg-icon
    margin-top: -3px
    color: var(--v-gris1-lighten2) !important

@media (min-width: 700px)
  // Campos de la tabla
  #tattooer-list
    th
      padding: 8px !important
    td:first-child, td:nth-child(2)
      white-space: nowrap
      text-overflow: ellipsis
      overflow: hidden
      max-width: 115px
      min-width: 115px
      padding: 5px !important
    td:nth-child(4)
      white-space: nowrap
      text-overflow: ellipsis
      overflow: hidden
      min-width: 120px
      max-width: 120px
      padding: 5px !important
    td:nth-child(6)
      white-space: nowrap
      text-overflow: ellipsis
      overflow: hidden
      min-width: 70px
      max-width: 70px
      padding: 0px !important
    td:nth-child(5)
      white-space: nowrap
      text-overflow: ellipsis
      overflow: hidden
      min-width: 100px
      max-width: 100px
      padding: 0px !important

    td:last-child
      min-width: 75px
      padding: 0px !important
    td:nth-child(3)
      white-space: nowrap
      text-overflow: ellipsis
      overflow: hidden
      min-width: 10px
      max-width: 179px
      padding: 5px !important
  //Fin de los campos de la tabla
</style>
